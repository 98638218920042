

















































import { defineComponent, onMounted, reactive, SetupContext, inject } from '@vue/composition-api'
import { apiClient, headerWithAccessToken, refreshAccessToken } from '@/libs'
import { NotificationInjectKey } from '@/hooks/useNotification'

import shortid from 'shortid'

export default defineComponent({
  setup(_, ctx: SetupContext) {
    const notify = inject(NotificationInjectKey)
    const state = reactive({
      currentCode: '',
      code: '',
      loading: false,
      error: false,
      validate: false,
    })
    const getCurrentInviteCode = async () => {
      const { uri } = ctx.root.$appConfig.endpoints.getInviteCode
      state.loading = true
      state.error = false
      const options = {
        headers: headerWithAccessToken(uri),
      }
      const res = await apiClient
        .get(uri, options)
        .then((res) => {
          refreshAccessToken(res.headers)
          return res.data
        })
        .catch((err) => {
          refreshAccessToken(err.response?.headers)
          state.error = true
          return false
        })
        .finally(() => {
          state.loading = false
        })
      if (res) {
        state.currentCode = res.invitation_code
        return
      }
    }

    const onClipboardEventHandler = (value: string) => {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          notify?.show('現在の招待コードをクリップボードにコピーしました。', 'success')
        })
        .catch(() => {
          notify?.show('現在の招待コードのコピーに失敗しました。', 'error')
        })
    }

    const rules = {
      invitation_code: [
        (value: string) => !!value || '招待コードを入力してください。',
        (value: string) => value.length <= 10 || '招待コードは10文字以内で入力してください。',
      ],
    }

    const updateInviteCode = async () => {
      state.loading = true
      const { uri } = ctx.root.$appConfig.endpoints.postInviteCode
      const data = {
        invitation_code: state.code,
      }
      const options = {
        headers: headerWithAccessToken(uri),
      }

      const response = await apiClient
        .post(uri, data, options)
        .then((res) => {
          refreshAccessToken(res.headers)
          return true
        })
        .catch((err) => {
          refreshAccessToken(err.response?.headers)
          state.error = true
          return false
        })
        .finally(() => {
          state.loading = false
        })
      if (response) {
        state.currentCode = state.code
        notify?.show('招待コードを更新しました。', 'success')
      }
    }

    const generateInviteCode = () => {
      state.code = shortid.generate()
    }

    onMounted(async () => {
      getCurrentInviteCode()
      generateInviteCode()
    })
    return {
      state,
      onClipboardEventHandler,
      generateInviteCode,
      updateInviteCode,
      rules,
    }
  },
})
