





import { defineComponent, PropType, computed } from '@vue/composition-api'
export default defineComponent({
  props: {
    items: {
      type: Array as PropType<{ id: number; name: string }[]>,
      required: true,
    },
    statusId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const colors = ['blue', 'amber accent-2', 'teal lighten-5', 'black white--text']
    const displayComputed = {
      addClasses: computed(() => {
        const { statusId } = props
        return colors[statusId - 1]
      }),
      label: computed(() => {
        const { items, statusId } = props
        const item = items.find((item) => item.id === statusId)
        return item ? item.name : ''
      }),
    }

    return {
      displayComputed,
    }
  },
})
