


















































































import { defineComponent, onMounted, reactive, ref, SetupContext } from '@vue/composition-api'
import { apiClient, formatDate, headerWithAccessToken, refreshAccessToken } from '@/libs'

// import { PaginationResponseType, PostsProps } from '@/types'
type PostsRules = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}
export default defineComponent({
  setup(_, ctx: SetupContext) {
    const config = ctx.root.$appConfig.endpoints
    const tab = ref(null)
    const datatableHeaders = {
      information: [
        {
          text: '投稿日',
          value: 'created_at',
        },
        {
          text: 'タイトル',
          value: 'title',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      workshop: [
        {
          text: '開催日',
          value: 'start_date',
        },
        {
          text: '開催時刻',
          value: 'start_time',
        },
        {
          text: '勉強会名',
          value: 'title',
        },
        {
          text: '場所',
          value: 'location',
        },
        {
          text: '', // files
          value: 'files',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      events: [
        {
          text: '開催日',
          value: 'start_date',
        },
        {
          text: '開催時刻',
          value: 'start_time',
        },
        {
          text: 'イベント名',
          value: 'title',
        },
        {
          text: '場所',
          value: 'location',
        },
        {
          text: '', // files
          value: 'files',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    }
    const postsRecords = reactive<PostsRules>({
      information: {
        type: 1,
        name: 'information',
        records: {
          current_page: 0,
          data: [],
          first_page_url: '',
          from: 1,
          last_page: 1,
          last_page_url: '',
          next_page_url: null,
          path: '',
          per_page: 10,
          prev_page_url: null,
          to: 1,
          total: 0,
        },
      },
      workshop: {
        type: 2,
        name: 'workshop',
        records: {
          current_page: 0,
          data: [],
          first_page_url: '',
          from: 1,
          last_page: 1,
          last_page_url: '',
          next_page_url: null,
          path: '',
          per_page: 10,
          prev_page_url: null,
          to: 1,
          total: 0,
        },
      },
      event: {
        type: 3,
        name: 'events',
        records: {
          current_page: 0,
          data: [],
          first_page_url: '',
          from: 1,
          last_page: 1,
          last_page_url: '',
          next_page_url: null,
          path: '',
          per_page: 10,
          prev_page_url: null,
          to: 1,
          total: 0,
        },
      },
    })

    const getPosts = async () => {
      const { uri } = config.getPosts
      Object.keys(postsRecords).forEach(async (key) => {
        await apiClient(uri, {
          params: {
            type: postsRecords[key].type,
          },
          headers: headerWithAccessToken(uri),
        })
          .then((res) => {
            refreshAccessToken(res.headers)
            postsRecords[key].records = res.data
          })
          .catch((err) => {
            refreshAccessToken(err.response.headers)
          })
      })
    }

    onMounted(async () => {
      await getPosts()
    })
    return {
      tab,
      postsRecords,
      datatableHeaders,
      formatDate,
    }
  },
})
