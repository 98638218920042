




































































































import { defineComponent, PropType, computed, ref, watch } from '@vue/composition-api'
import { AuthState, InitResponseType } from '@/types'
import { useUser } from '@/hooks/useUser'
type UserEditState = {
  open: boolean
  item: AuthState
  request: AuthState
  loading: boolean
  error: null
}
export default defineComponent({
  props: {
    data: {
      type: Object as PropType<UserEditState>,
      required: true,
    },
    options: {
      type: Object as PropType<InitResponseType>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { updateState, update } = useUser()
    const userUpdate = async () => {
      const { id, capabilitie_investment_id, status_id, capabilitie_life_id, role_id } = props.data.request
      const req = {
        id,
        role_id,
        capabilitie_investment_id,
        status_id,
        capabilitie_life_id,
      }
      const result = await update(req)
      open.value = null
      emit('update', {
        result,
        requestData: req,
      })
    }

    const accountActive = ref(false)
    const open = ref(null)
    const accountActiveStatement = computed(() => {
      let ret = {
        title: 'アカウントの有効化',
        type: 'primary',
        message: '有効にするとこのアカウントはログインできるようになります。',
      }
      if (props.data.item.active === 1) {
        ret = {
          ...ret,
          title: 'アカウントの無効化',
          type: 'error',
          message: '無効にするとこのアカウントはログインできなくなります。',
        }
      }
      return ret
    })

    const onCancelEventHandler = () => {
      emit('cancel')
      open.value = null
    }

    const onAccountState = async () => {
      const { id } = props.data.request
      const req = {
        id,
        active: accountActive.value ? 1 : 0,
      }
      const result = await update(req)
      emit('update', {
        result,
        requestData: req,
      })
    }

    watch(
      () => props.data.item.active,
      (n) => {
        if (typeof n === 'undefined') return
        accountActive.value = n === 1
      }
    )

    return {
      userUpdate,
      updateState,
      onCancelEventHandler,
      accountActiveStatement,
      accountActive,
      open,
      onAccountState,
    }
  },
})
