import { reactive, computed, toRefs } from '@vue/composition-api'
import axios from 'axios'
import { UserItemType, AxiosPaginationResponseType, AuthState } from '@/types'
import { headerWithAccessToken, refreshAccessToken } from '@/libs'
import config from '@/config'

type UserUpdateType = Omit<
  AuthState,
  'id' | 'capabilitie_investment_id' | 'status_id' | 'capabilitie_life_id' | 'role_id'
>
export type UserUpdateReturnType = {
  result: boolean
  requestData: {
    id: number
    role_id: number
    status_id: number
    capabilitie_investment_id: number
    capabilitie_life_id: number
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUser = () => {
  const client = axios.create({
    withCredentials: true,
  })
  const state = reactive<{
    loading: boolean
    data: AxiosPaginationResponseType<UserItemType>
    error: string
  }>({
    loading: false,
    data: {
      current_page: 1,
      data: [],
      first_page_url: '',
      from: 1,
      last_page: 1,
      last_page_url: '',
      next_page_url: null,
      path: '',
      per_page: 15,
      prev_page_url: null,
      to: 1,
      total: 0,
    },
    error: '',
  })
  const updateState = reactive({
    loading: false,
    result: false,
    error: false,
  })

  const update = async (data: UserUpdateType): Promise<boolean> => {
    updateState.loading = true
    const { uri } = config.endpoints.userUpdate
    const url = uri.replace(':id', data.id.toString())
    const options = {
      headers: headerWithAccessToken(url),
    }
    return await client
      .put(url, data, options)
      .then((res) => {
        refreshAccessToken(res.headers)
        return true
      })
      .catch((err) => {
        refreshAccessToken(err.response.headers)
        return false
      })
      .finally(() => {
        updateState.loading = false
      })
  }
  const fetchUsers = async () => {
    state.loading = true
    const url = config.endpoints.admin.users.uri
    const options = {
      headers: headerWithAccessToken(url),
    }
    await client
      .get<AxiosPaginationResponseType<UserItemType>>(url, options)
      .then((res) => {
        refreshAccessToken(res.headers)
        state.data = res.data
      })
      .catch((err) => {
        refreshAccessToken(err.response.headers)
        state.error = 'Failed to fetch users'
      })
      .finally(() => {
        state.loading = false
      })
  }
  const paginateMeta = computed(() => {
    return {
      current_page: state.data.current_page,
      last_page: state.data.last_page,
      per_page: state.data.per_page,
      total: state.data.total,
      next_page_url: state.data.next_page_url,
      prev_page_url: state.data.prev_page_url,
    }
  })

  const pagination = async (page: number) => {
    state.loading = true
    const url = config.endpoints.admin.users.uri
    await client
      .get<AxiosPaginationResponseType<UserItemType>>(url, {
        params: {
          page: page,
        },
        headers: headerWithAccessToken(url),
      })
      .then((res) => {
        refreshAccessToken(res.headers)
        state.data = res.data
      })
      .finally(() => {
        state.loading = false
      })
  }
  return {
    ...toRefs(state),
    updateState,
    fetchUsers,
    pagination,
    paginateMeta,
    update,
  }
}
