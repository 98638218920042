
























































import { defineComponent, onMounted, ref, reactive, SetupContext, inject } from '@vue/composition-api'
import { AdminDataTableHeader } from '@/const/admin'
import { useUser, UserUpdateReturnType } from '@/hooks/useUser'
import { useInit } from '@/hooks/useInit'
import StatusLabel from '@/components/Parts/StatusLabel.vue'
import AdminUserUpdate from '@/components/Admin/AdminUserUpdate.vue'
import AdminPostContainer from '@/components/Admin/AdminPostContainer.vue'
import InviteManage from '@/components/Admin/InviteManage.vue'
import dayjs from 'dayjs'
import { AuthState } from '@/types'
import axios from 'axios'
import { NotificationInjectKey } from '@/hooks/useNotification'
import { headerWithAccessToken, refreshAccessToken } from '@/libs'

export default defineComponent({
  components: {
    StatusLabel,
    AdminUserUpdate,
    AdminPostContainer,
    InviteManage,
  },
  setup(_, ctx: SetupContext) {
    const notification = inject(NotificationInjectKey)
    const { data, loading, fetchUsers, pagination, paginateMeta } = useUser()
    const { data: initData, fetch: initFetch } = useInit()
    const userEditState = reactive({
      open: false,
      item: {} as AuthState,
      request: {} as AuthState,
      loading: false,
      error: null,
    })
    const isAllowAccess = ref(false)
    const dateFormat = (date: string, format = 'YYYY/MM/DD') => {
      const d = dayjs(date)
      if (!d.isValid()) {
        return ''
      }
      return d.format(format)
    }

    const onCsvExportEventHandler = async () => {
      const { uri } = ctx.root.$appConfig.endpoints.csvExport
      const options = {
        headers: headerWithAccessToken(uri),
      }
      const response = await axios
        .get(uri, options)
        .then((res) => {
          refreshAccessToken(res.headers)
          return res.data
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          return false
        })
      if (!response) {
        notification?.show('CSVのダウンロードに失敗しました', 'error')
        return
      }
      const dl = `${ctx.root.$appConfig.s3}${response.url}`
      const a = document.createElement('a')
      a.href = dl
      a.download = 'users.csv'
      a.click()
    }

    const onEditEventHandler = (item: AuthState) => {
      userEditState.item = item
      userEditState.open = true
      userEditState.request = JSON.parse(JSON.stringify(item)) as AuthState
    }

    const onCancelEventHandler = () => {
      userEditState.open = false
      userEditState.item = {} as AuthState
      userEditState.request = {} as AuthState
    }

    const updateSuccessHandler = (value: UserUpdateReturnType) => {
      if (value.result) {
        notification?.show('ユーザー情報を更新しました', 'success')
        fetchUsers()
        onCancelEventHandler()
      } else {
        notification?.show('ユーザー情報の更新に失敗しました', 'error')
      }
    }

    const accountActiveIcon = (value: number) => {
      if (value) {
        return 'mdi-account'
      }
      return 'mdi-account-off'
    }

    onMounted(async () => {
      const user = ctx.root.$auth.user()
      if (user?.role_id !== 1) {
        return ctx.root.$router.push('/')
      }
      await fetchUsers()
      await initFetch()
      isAllowAccess.value = true
    })
    return {
      AdminDataTableHeader,
      data,
      loading,
      pagination,
      paginateMeta,
      dateFormat,
      isAllowAccess,
      onCsvExportEventHandler,
      onEditEventHandler,
      userEditState,
      initData,
      onCancelEventHandler,
      updateSuccessHandler,
      accountActiveIcon,
    }
  },
})
