/**
 * ユーザー一覧に利用する
 */
export const AdminDataTableHeader = [
  {
    text: '',
    value: 'edit',
    width: '5%',
  },
  {
    text: '会員番号',
    align: 'start',
    value: 'id',
    width: '100px',
  },
  {
    text: 'ロール',
    align: 'start',
    value: 'role',
    width: '130px',
  },
  {
    text: 'ステータス',
    align: 'start',
    value: 'status',
    width: '120px',
  },
  {
    text: '資格:人生',
    align: 'start',
    value: 'capabilitie_life',
    width: '120px',
  },
  {
    text: '資格:投資',
    align: 'start',
    width: '120px',
    value: 'capabilitie_investment',
  },
  {
    text: 'ユーザー名',
    align: 'start',
    width: '220px',
    value: 'username',
  },
  {
    text: 'メールアドレス',
    value: 'email',
    width: '220px',
  },
  {
    text: '名前',
    align: 'start',
    value: 'name',
    width: '220px',
  },
  {
    text: 'ふりがな',
    align: 'start',
    value: 'name_kana',
    width: '220px',
  },
  {
    text: '生年月日',
    value: 'date_of_birth',
    width: '150px',
  },
  {
    text: '性別',
    align: 'start',
    value: 'sex',
    width: '100px',
  },
  {
    text: '出生地',
    align: 'start',
    value: 'brithpalce',
    width: '200px',
  },
  {
    text: '現住所',
    align: 'start',
    value: 'address',
    width: '200px',
  },
  {
    text: '紹介者',
    align: 'start',
    value: 'introducer',
    width: '200px',
  },
  {
    text: '関係',
    align: 'start',
    value: 'relationship_introducer',
    width: '200px',
  },
  {
    text: 'PR',
    align: 'start',
    value: 'pr',
    width: '250px',
  },
  {
    text: '登録日',
    align: 'start',
    width: '100px',
    value: 'created_at',
  },
  {
    text: '最終ログイン',
    align: 'start',
    width: '150px',
    value: 'last_login_at',
  },
]
